const books = [
    {
        image: 'book1.jpeg',
        bookLink: '#'
    },
    {
        image: 'book2.jpg',
        bookLink: '#'
    },
    {
        image: 'book3.jpeg',
        bookLink: '#'
    }
];

export default books;