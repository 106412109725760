import { Card, CardMedia } from "@mui/material";
import { Box } from '@mui/system';

import books from "../constants/books";


export default function BooksImReading({ isMobile }) {

    const bookCardStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: '20vh',
        borderRadius: 3,
        width: isMobile ? '30vw' : '10vw',
        marginRight: '3vw'
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {
                books.map((book) => (
                    <Card sx={bookCardStyle}>
                        <CardMedia
                            component="img"
                            sx={{ height: '20vh' }}
                            image={book.image}
                            alt="Book cover"
                        />
                    </Card>
                ))
            }
        </Box>
    );
}