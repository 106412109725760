const songs = [
    {
        title: 'What is Love',
        artist: 'Haddaway',
        albumLink: 'album.jpeg'
    },
    {
        title: 'You Stepped Into My Life',
        artist: 'Bee Gees',
        albumLink: 'album2.jpeg'
    },
    {
        title: 'Art of Heartbreak',
        artist: 'Daryll Hall and John Oates',
        albumLink: 'album3.jpeg'
    }
];

export default songs;