const cardStyle = {
    borderRadius: 8,
    backgroundColor: '#f3f3f3f7',
    marginBottom: 2
};

const textStyle = {
    fontFamily: 'DongleRegular',
};

const paragraphStyle = {
    fontSize: '24px'
};

export { cardStyle, textStyle, paragraphStyle };