import { CardContent, Typography, Card, CardMedia } from '@mui/material';
import { Box } from '@mui/system';

import { textStyle, cardStyle, paragraphStyle } from '../styles';
import songs from '../constants/songs';

export default function RecentSongs({isMobile}) {

    const musicCardStyle = { borderRadius: 3, display: 'flex', justifyContent: 'space-between', width: isMobile ? '80vw' : '18vw', backgroundColor: '#202124', marginBottom: '1vh', height: '12vh' };

    return (
        <Card sx={{ ...cardStyle, display: 'flex', justifyContent: 'center' }} variant="outlined">
            <CardContent>
                <Box>
                    <Typography gutterBottom variant="h4" component="div" align="center" sx={{ ...textStyle }}>
                        Recent Songs I Played
                    </Typography>
                </Box>
                <Box>
                    {
                        songs.map((song) => (
                            <Card sx={musicCardStyle}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent>
                                        <Typography sx={{ ...textStyle, color: '#fff' }} component="div" variant="h5">
                                            {song.title}
                                        </Typography>
                                        <Typography sx={{ ...textStyle, ...paragraphStyle, color: 'lightgray' }} variant="subtitle1" color="text.secondary" component="span">
                                            {song.artist}
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 120, height: '12vh' }}
                                    image={song.albumLink}
                                    alt="Album cover"
                                />
                            </Card>
                        ))
                    }
                </Box>
            </CardContent>
        </Card>
    );
}