import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardContent, Chip, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import CodeIcon from '@mui/icons-material/Code';
import ExtensionIcon from '@mui/icons-material/Extension';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DrawIcon from '@mui/icons-material/Draw';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';


import RecentSongs from './components/RecentSongs';
import { textStyle, paragraphStyle, cardStyle } from './styles';
import BooksImReading from './components/BooksImReading';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function MyApp() {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      sx={{
        paddingLeft: isMobile ? 1 : 10,
        paddingRight: isMobile ? 1 : 10,
        marginTop: '5vh', height: '100%', flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <Box sx={{ width: isMobile ? '85vw' : '30vw', paddingRight: '3vw' }}>
        <Card sx={cardStyle} variant="outlined">
          <CardMedia
            component="img"
            image="./profile.jpeg"
            title="that's my college pic haha"
          />
          <CardContent>
            <Box>
              <Typography gutterBottom variant="h3" component="div" align="center" sx={textStyle}>
                Tricia Jasmine Moreno
              </Typography>

              <Typography variant="subtitle1" component="div" align="center" sx={{ ...textStyle, ...paragraphStyle }}>
                Software Engineer | Amateur Pianist | Stock Market Nerd
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <GitHubIcon fontSize="small" />
                <Link target="_blank" variant="subtitle1" href="https://github.com/hecklerhack" sx={{ ...textStyle, ...paragraphStyle, marginLeft: '3px' }}>
                  hecklerhack
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <RecentSongs isMobile={isMobile} />
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{ width: isMobile ? '85vw' : '55vw' }}
      >
        <Card sx={{ p: 3, ...cardStyle }} variant="outlined">
          <Typography variant="h3" sx={textStyle}>
            Hi, um, where do I begin?
          </Typography>
          <Typography variant="body1" sx={{ ...textStyle, ...paragraphStyle }}>
            Hey my name is Tricia, or just Trish...but some college friends call me Tree (lol). Some call me Jasmine
            (like the rice, not the flower...and certainly not the Disney Princess). I like puzzles, strategy games, and solving
            (and creating) problems. When I say I like them, I meant I will NEVER STOP until I solved (or created?) the problem.  <br></br><br></br>
            So um yeah...welcome to my website! Sit back, have some tea, look at some tidbits about me, feel free!
          </Typography>
        </Card>
        <Card sx={{ p: 3, ...cardStyle }} variant="outlined">
          <Typography variant="h4" sx={textStyle}>
            Interests
          </Typography>
          <Box
            marginTop={3}
            display="flex"
            justifyContent={"space-around"}
            flexWrap={"wrap"}
          >
            <Chip icon={<CodeIcon style={{ color: "#2B60DE" }} />} label="Programming" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
            <Chip icon={<ExtensionIcon style={{ color: "#50C878" }} />} label="Puzzles" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
            <Chip icon={<AutoStoriesIcon style={{ color: "#C37135" }} />} label="Books" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
            <Chip icon={<ShowChartIcon style={{ color: "#40C335" }} />} label="Stock Market" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
            <Chip icon={<DrawIcon style={{ color: "#C33558" }} />} label="Anime & Cartoons" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
            <Chip icon={<VideogameAssetIcon style={{ color: "#9FC335" }} />} label="Video Games" variant="outlined" sx={{ marginBottom: 2, ...textStyle, ...paragraphStyle }} />
          </Box>
        </Card>
        <Card sx={{ p: 3, ...cardStyle }} variant="outlined">
          <Typography variant="h4" gutterBottom={true} sx={textStyle}>
            Fun Stuff I Did
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', ...textStyle, ...paragraphStyle }}>
            <Link target="_blank" variant="subtitle1" href="https://www.facebook.com/100000141106787/videos/551130096022061/" sx={{ ...textStyle, ...paragraphStyle, marginLeft: '3px' }}>
              Reptile Hop (Arduino Dino Game)
            </Link>
          </Typography>
        </Card>
        <Card sx={{ p: 3, ...cardStyle }} variant="outlined">
          <Typography variant="h4" gutterBottom={true} sx={textStyle}>
            Books I'm Reading
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', ...textStyle, ...paragraphStyle }} align="center">
            <BooksImReading isMobile={isMobile} />
          </Typography>
        </Card>
      </Box>
    </Box>
  );
}
